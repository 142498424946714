import logo from "./logo.svg";
import "./App.css";
import Hero from "./comps/hero";
import AboutSection from "./comps/about";
import Header from "./comps/header";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";
import Parental from "./comps/parental";
import Activities from "./comps/activities";
import Blog from "./comps/blog";
import Content from "./comps/content";
import ShortContent from "./comps/shortContent";
import Footer from "./comps/footer";
import Awareness from "./comps/awareness";
import Prevention from "./comps/prevention";
import Cure from "./comps/cure";
import PublicAdvisory from "./comps/publicAdvisory";
import Intervention from "./comps/intervention";
import Soon from "./comps/soon";
import Community from "./comps/community";
import CTACommunity from "./comps/ctaCommunity";
import ContactUs from "./comps/contactus";
import { useEffect } from "react";

function App() {


  //  window.googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: true,
  //       includedLanguages: "ta,te,hi",
  //       // layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   const addScript = document.createElement('script');
  //   addScript.setAttribute(
  //       'src',
  //       '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
  //   );
  //   document.body.appendChild(addScript);
    // debugger
    // window.googleTranslateElementInit = () => {
    //     new window.google.translate.TranslateElement(
    //         {
    //             pageLanguage: 'auto',
    //             autoDisplay: false,
                
    //         },
    //         'google_translate_element'
    //     );
    // };
// }, []);

  return (
    
    <Router>
             

      <div className="App font_poppins">
     
        <Header />
        
        <Routes>
        
          <Route
            exact
            path="/"
            element={
              <>
              
                <Hero />
                <Content />
                <AboutSection />
                <ShortContent />
                {/* <Blog /> */}
                <CTACommunity />
              </>
            }
          ></Route>

          <Route
            exact
            path="/parental_alienation"
            element={<Parental />}
          ></Route>

          <Route
            exact
            path="/activities/awareness"
            element={<Awareness />}
          ></Route>
          <Route
            exact
            path="/activities/prevention"
            element={<Prevention />}
          ></Route>
          <Route exact path="/activities/Cure" element={<Cure />}></Route>
          <Route
            exact
            path="/activities/public_advisory"
            element={<PublicAdvisory />}
          ></Route>
          <Route
            exact
            path="/activities/anantaa_intervention"
            element={<Intervention />}
          ></Route>

          <Route exact path="/blog" element={<Soon />}></Route>
          <Route exact path="/news-events" element={<Soon />}></Route>
          <Route exact path="/community" element={<Community />}></Route>
          <Route exact path="/contact" element={<ContactUs />}></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
