import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import Consulting from "./../assets/parential_consulting.jpg";

export default function ShortContent() {
  return (
    <div className="overflow-hidden bg-white pb-14 sm:pb-24  flex flex-col gap-14 sm:gap-24 lg:gap-36">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className=" grid grid-cols-1 gap-6 lg:gap-10 lg:grid-cols-2  lg:items-start ">
          <div className="mx-auto w-full md:w-[680px] lg:mx-0 lg:w-full">
            <img
              src={Consulting}
              alt="Consulting"
              width={500}
              height={500}
              className="md:h-[410px] w-full object-cover rounded-lg sm:rounded-xl bg-gray-800 ring-1 ring-white/10"
            />
          </div>

          <div className="  flex items-center justify-end h-full">
            <div className="mx-auto w-full md:w-[680px] lg:mx-0 lg:max-w-lg">
              <h2 className="text-lg sm:text-2xl font-semibold text-[#00CAB9] ">
                Anantaa actively combats parental alienation through education,
                intervention, and unwavering support
              </h2>
              <p className="mt-3 text-lg text-justify  text-gray-600">
                Anantaa is dedicated to raising awareness about parental
                alienation, offering intervention services to prevent or address
                its harmful effects. Through education, advocacy, and support,
                Anantaa strives to empower individuals and communities to
                recognize and combat parental alienation, promoting the
                well-being of children and families.
              </p>
              <div className="mt-8 flex items-center gap-x-6 w-[fit-content] ">
                <a
                  href="/activities"
                  className="min-w-[150px] text-center rounded-md bg-[#00CAB9] px-3 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-[#00CAB9] "
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
