import { CameraIcon, MegaphoneIcon, ShieldCheckIcon, SparklesIcon } from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

import HappyFamily from "./../assets/happy_family.jpg";



export default function AboutSection() {
  return (
    <div className="overflow-hidden bg-white py-24">
      <div className="relative mx-auto max-w-7xl px-6 sm:py-16 lg:px-8">
        <div className="absolute bottom-0 left-3/4 top-0 hidden  bg-gray-50 lg:block" />
        <div className=" text-base lg:grid  lg:grid-cols-2  lg:gap-8 mx-auto  md:max-w-2xl lg:max-w-full">
          <div>
            <h2 className="text-lg font-semibold text-[#00CAB9]">About Us</h2>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center   mx-auto  md:max-w-2xl lg:max-w-full">
          <div className="relative lg:col-start-2 lg:row-start-1 lg:pl-8">
            <svg
              className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>

            <div className="aspect-h-7 aspect-w-9 ">
              <img
                className="rounded-lg object-cover object-center shadow-lg"
                src={HappyFamily}
                alt="Happy family"
              />
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-left flex flex-col  gap-4 ">
              <p className="text-left text-lg text-gray-500 text-justify">
                Parental Alienation is one of the severe forms of child abuse
                that is probably not talked about much. Parental Alienation is
                an Emotional Child Abuse, which not only damages(robs) the only
                childhood of the child but also leaves a permanent psychological
                scar on the child that may extend well into the adulthood.
              </p>
              <p className="text-left text-lg text-gray-500 text-justify">
                As parental alienation is difficult to understand unless one is
                going through it, we want to be the VOICE behind the VOICELESS
                children going through this brutal form of abuse without even
                knowing that it’s an abuse. We aim to tackle Parental Alienation
                in many ways that focus on prevention in the first place rather
                than cure.
              </p>
              <div>
                <h2 className="text-lg font-semibold text-[#00CAB9] mt-2 w-max">
                  Our Vision
                  
                </h2>
                <p className="text-left text-lg text-gray-500 mt-2">
                  To ensure that the childhood is a pleasant one for the child
                  with care &amp; support of both the parents and strive to
                  remove “alienated children” from the lexicon.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-28 lg:max-w-4xl text-left">
          <h2 className="text-lg font-semibold text-[#00CAB9] text-center">
            Our Mission
          </h2>
          <dl className="flex flex-col gap-8 mt-8 sm:mt-14">
            <div className="flex md:flex-row flex-col gap-8 text-justify">
              <div className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 p-[10px] flex h-10 w-10 items-center justify-center rounded-full bg-[#00CAB9]">
                    <ShieldCheckIcon
                      className=" text-white"
                      aria-hidden="true"
                    />
                  </div>
                  Preventive Intervention
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  To identify families /children at risk and work to prevent
                  parental alienation by indulging in various intervention
                  mechanisms.
                </dd>
              </div>
              <div className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 p-[10px] flex h-10 w-10 items-center justify-center rounded-full bg-[#00CAB9]">
                    <SparklesIcon
                      className=" text-white"
                      aria-hidden="true"
                    />
                  </div>
                  Reconciliation Efforts
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  To identify alienated children and strive to bring back /
                  normalize the parent child relationship.
                </dd>
              </div>
            </div>

            <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute left-0 top-0 flex p-[10px] h-10 w-10 items-center justify-center rounded-full bg-[#00CAB9]">
                  <MegaphoneIcon
                    className=" text-white"
                    aria-hidden="true"
                  />
                </div>
                Promoting Awareness & Education
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600 text-justify">
                To raise awareness and provide education about this pandemic of
                mental and emotional child abuse by reaching out to General
                Public, all stakeholders as well as the perpetrators who may be
                unaware of the disastrous effect of their alienating behaviour
                on the children they purport to love.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
