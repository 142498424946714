
import Sad from "./../assets/parential_alientaion.jpg";
export default function Content() {
  return (
    <div className="w-full bg-[#00CAB9] h-full mt-24 overflow-hidden">
      <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8 ">
        <div className="absolute bottom-0 left-3/4 top-0 hidden  bg-gray-50 lg:block" />
        <div className="md:mt-8 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center  mx-auto  md:max-w-2xl lg:max-w-full">
          <div className="lg:pr-8 ">
            <svg
              className="absolute  left-[-20%] md:left-[0%] lg:left-0 top-[-20%] lg:top-[20%] lg:-ml-16   lg:-mt-20 block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>

            <div className="aspect-h-5 aspect-w-7 ">
              <img
                className="rounded-lg object-cover object-center shadow-lg "
                src={Sad}
                alt="Parential alienation"
              />
            </div>
          </div>

          <div className="mt-8 lg:mt-0 h-max ">
            <div className="text-left flex flex-col  gap-4">
              <p className="text-left text-lg text-white text-justify">
                Parental Alienation, occurs when one parent turns a child
                against the other parent and his or her family. It is a
                systematic process of denigration and psychological manipulation
                of the child by one parent with the intent of disrupting what
                would otherwise be a loving child – parent bond. This behaviour
                often accompanies high conflict divorces, where one parent uses
                the child as a pawn to revenge the spouse or inflict pain as a
                consequence for divorce/separation. This behaviour causes a
                child to be mentally manipulated or bullied into believing a
                loving parent is the cause of all the problems, and/or is an
                enemy to be feared, hated, disrespected and avoided.
              </p>

              <div className="mt-8 flex items-center gap-x-6 w-[fit-content] ">
                <a
                  href="/parental_alienation"
                  className="min-w-[150px] text-center rounded-md bg-white px-3 py-2.5 text-base font-semibold text-gray-900 shadow-sm  "
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
