import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Banner from "./../assets/1.jpg";


export default function Hero() {
  return (
    <div className="bg-gray-900">
      <div className="relative isolate overflow-hidden bg-gray-600 pt-12 md:pt-8 px-6 lg:px-8">
        <img
          src={Banner}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover mix-blend-overlay"
        />

        <div className="mx-auto max-w-4xl py-32 sm:py-48 lg:py-48">
          <div className="text-center">
            <h1
              style={{ lineHeight: 1.2 }}
              className="text-4xl font-bold text-white sm:text-[54px] "
            >
              Rebuilding Trust, Restoring Love, Our Journey Against Parental
              Alienation
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-100 text-justify">
              Caring for families and safeguarding children, Anantaa's mission
              stands against the insidious threat of parental alienation.
              Through advocacy, intervention, and support, we illuminate the
              path toward healing and reconciliation, nurturing resilient bonds
              and empowering parents and children alike.
            </p>
            <div className="mt-10 flex flex-col gap-y-4 sm:flex-row items-center justify-center gap-x-6">
            <a
                  href="/contact"
                  className="min-w-[150px] text-center rounded-md bg-[#00CAB9] text-white px-3 py-2.5 text-base font-semibold shadow-sm  "
                >
                  Contact Us
                </a>

              <a
                  href="/parental_alienation"
                  className="min-w-[150px] text-center rounded-md  px-3 py-2.5 text-base font-semibold text-gray-100 border shadow-sm  "
                >
                Learn more
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
