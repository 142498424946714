export default function Prevention() {
  const relationshipTips = [
    {
      point:
        "It’s said 'when the going gets tough, the tough get's going'. When you experience relationship problems and find it difficult to resolve between yourselves, the first thing to practice is self-restraint in all aspects. Self-restraint in speech, words, emotions, and actions.",
    },
    {
      point:
        "Though very difficult to practice, especially when emotions are running high, it is to be noted that only a calm mind can think clearly and act responsibly. Therefore, withdraw yourself from the heat of the emotions and get into a space where you can regain your balance and think of ways to handle the underlying issues in the relationship. Meditation can help to regain composure and calm the mind.",
    },
    {
      point:
        "When you are trying to fix the problems in the relationship, as much as possible, avoid talking about the problems with everyone around and try to get sympathy or ally for your cause. This will only show the other partner in a bad light and when it reaches the other partner (it is not 'if' but only 'when' it will reach the other partner), will escalate the problem even further. The other partner is likely to take it as if you are spreading false information about him/her and likely to retaliate in the same manner. This is surely a relationship breaker as it will lead to a lot of gossips to float around and the partners are likely to feel deep hurt / insult which will make the reconciliation extremely difficult.",
    },
    {
      point:
        "Moreover, when you talk about the problems to everyone around, some people may use it to exploit the strain in the relationship to further their interests in any manner possible. They may use it to level scores for any past incidents, act out of jealousy and vengeance or usurp any of your possessed belongings including property, business etc.",
    },
    {
      point:
        "If you feel the need to discuss with someone to either take opinions or mediate with your partner, please be very choosy of the person whom you confide to discuss your problems with. If they are not having the patience and ability to mediate, they can cause more harm and aggravate the problem further.",
    },
    {
      point:
        "Always remember that it's Love, Compassion, Patience and above all 'FORGIVENESS' that can save a relationship and not hostile actions of any form. Therefore, have patience to understand the above or take advice from elderly people who have well lived their lives. Never indulge in any aggressive behaviors and hostile actions like removing the child or any action that will give irreparable loss to your partner. Resist the temptation to act with vengeance even if you have been wronged. Keep your thoughts / actions good and good things will eventually start unfolding to you.",
    },
  ];
  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div className="pt-6">
          <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Prevention
          </h2>

          <p className="font-semibold  pt-4">
            Marriage and Child custody law changes
          </p>
          <p className="pt-4 text-justify">
            The only effective means to combat and eliminate parental alienation
            is to address it by means of a multi-faceted approach that involves
            fundamental changes to the present family law system with respect to
            divorce and child custody. <br />
            <br />
          </p>
          <ul className="list-disc ml-4 sm:ml-12 text-justify">
            <li>
            <span className="font-semibold text-black text-justify">
                Professional recognition of parental alienation as a serious
                form of child abuse, and corresponding intervention of child
                protection authorities.
              </span>{" "}
              First and foremost, we need to recognize parental alienation as a
              form of individual child abuse that requires a swift child
              protection response, no different than physical and sexual abuse.
              This may involve child removal from the abusive parent or, in most
              cases, family support services aimed at educating the parent about
              the effects and unacceptability of alienation and effecting a
              reunification process between the child and the targeted parent.
              In India, court cases take a long time to reach judgement and the
              intervening period when the child is in the custody of the
              alienating parent becomes a fertile ground for alienation to
              thrive and flourish. When a divorce or child custody case reaches
              the court, the court should immediately act to ensure the child is
              not affected by the dispute of the parents and take action to
              ensure alienation does not happen while the case proceeds in
              court.
              <br />
              <br />
              Internationally, parental alienation is recognised as a form of
              parental psychological abuse. It undermines the core principles of
              the{" "}
              <span className="font-semibold text-black text-justify">
                {" "}
                Universal Declaration of Human Rights and the UN Convention on
                the Rights of the Child,
              </span>{" "}
              which states a child who is separated from one or both parents has
              the right to maintain “personal relations and direct contact with
              both parents on a regular basis, except if it is contrary to the
              child’s best interests’. <br />
              <br />
            </li>

            <li>
            <span className="font-semibold text-black text-justify">
                Fundamental reform of the family law system, and establishing
                shared parenting
              </span> :{" "}
               Parental Alienation is also a form of collective child abuse; that
              is, alienation flourishes within legal structures that remove a
              parent from a child’s life by means of primary residence orders in
              disputed cases. Adversarial “winner-take-all” systems force
              parents to denigrate each other to prove that they are the
              superior parent and more worthy of being granted primary caregiver
              status, in effect engaging in alienating behaviours. The system
              thereby encourages and produces alienating behaviour. In India,
              maybe, the time has come to treat child abduction by a parent
              without the knowledge of the other parent as a punishable criminal
              offence. <br />
              <br />
            </li>

            <li>
            <span className="font-semibold text-black text-justify">
                Provision of effective treatment programs and services by
                trained service providers, including reunification services and
                prevention programs.{" "}
              </span>
              The provision of effective treatment services by trained service
              providers, including reunification services and prevention
              programs, is vital to restoring the relationship between children
              and targeted parents. Reunification efforts should be undertaken
              with service providers with specialized expertise in parental
              alienation reunification. Further, if the trauma of alienation is
              severe, therapeutic services to individual victims of
              alienation are urgently needed.
            </li>
            <br />
          </ul>

          <p className="font-semibold  pt-2">
            Professional Marital counselling
          </p>
          <p className="pt-4 text-justify">
            It is very important to strive to combat parental alienation by
            preventing it from happening in the first place. First and the
            foremost is for the couple to identify warning signs in a
            relationship and seek professional help. Most times, it is the
            unhealthy ways of communication that is at the root of the problem
            and seeking professional assistance can help in resolving the issues
            before it escalates into a conflict. <br />
            <br />
          </p>

          <p className="font-semibold  pt-2">Couple Advisory</p>
          <p className="pt-4 text-justify">
            We at Anantaa have few advices for couples who are struggling with
            relationship issues. These are not derived from any literature study
            or hearsay. It is based on personal experiences of individuals who
            have been victims of parental alienation and who could neither save
            the relationship nor the child.
            <br />
          </p>
          <ol className="list-disc ml-4 sm:ml-12 pt-4 text-justify">
            {relationshipTips.map((item, index) => (
              <li key={index} className="pt-4">
                {item?.point}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}
