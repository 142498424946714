export default function Cure() {
  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div className="pt-6 text-justify">
          <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Cure
          </h2>

          <p className="font-semibold  pt-4">1. Policy</p>
          <p className="pt-4 ">
            While its best to prevent parental alienation from happening, the
            legal system could also play its part to see if there is any cure
            available.
            <br />
            <br /> Child custody cases are, mostly, fought very acrimoniously by
            the couple that it eventually leads to breakdown of the marriage.
            Moreover, in the absence of shared parenting laws in India, the
            courts are likely to give the child custody to one parent with
            visitation rights for the other parent. Fresh from the scars of the
            legal battle, the parent with the custody of the child is more
            likely to withhold the child from visiting the other parent as a
            means to punish him/her, clearly in violation of the court orders.
            The legal system, at-least in India, has no clear ways to monitor
            the execution of visitation rights and compliance of the order. The
            parent with visitation rights is left aggrieved even after the court
            order and having just completed a long drawn legal battle, is
            exhausted to fight against this injustice and eventually gives up on
            visitation. <br />
            <br /> The child, voiceless in the entire conflict, silently becomes
            a mere spectator and goes through enormous emotional turbulence in
            the early childhood years and carries the risk of developing severe
            psychological issues in the later part of life. Removed from sight
            of the other parent, the child parent bond eventually fades away
            leaving irreparable loss to a child and the estranged parent.
          </p>
          <ol className="list-disc ml-4 sm:ml-12 pt-4">
            <li className="pt-4">
            <span className="font-semibold text-black">
                Effective legal enforcement of Court orders with respect to
                custody, visitation etc, and legal consequences for parents who
                violate the orders
              </span>
              <br />
              Changes to the child protection and family law systems, as well as
              the availability of effective treatment programs, are in
              themselves insufficient. Legal system must include meaningful
              consequences for failure to comply with custody and visitation
              orders. Law enforcement is needed to ensure compliance, as well as
              consequences for engaging in parental alienating behaviours. In
              addition to being a form of emotional child abuse, parental
              alienation is a form of domestic violence directed at the targeted
              parent.
            </li>
          </ol>
          <br />

          <p className="font-semibold pt-2 ">
            2. Couple advisory / Guiding thoughts
          </p>
          <p className="pt-4">
            {" "}
            Whatever be the state of the relationship of the couple, there are
            always ways to restore the parent child relationship. However good
            maybe the intention of the legal system, legal recourse may not be
            the perfect solution in all circumstances involving restoring lost
            child parent relationships. In the best interests of the child, it
            is advisable for the withholding parent to realize the mistake
            committed over the years in blocking parent child relationship and
            allow the relationship to resume by encouraging the child. This is
            not an easy task for the custodial parent and one has to heal
            himself/herself from the injuries of the past to make such a
            decision.
            <br />
            <br />
            However, this is the best remedy for lost parent child relationship
            to resume and the sooner the custodial parent realizes the
            importance of the other parent in the life of the child, the better
            it is for the child. In the best interest of the child, even if
            there are unresolved issues between the parents, the custodial
            parent should encourage restoration of the parent child relationship
            by keeping his/her differences with another parent aside. <br />
            <br />
            Anantaa, preaches “FORGIVENESS” as the solution and would strongly
            appeal to the custodial parent to rise above the conflict and act in
            the best interest of the child.
          </p>
        </div>
      </div>
    </div>
  );
}
