export default function Intervention() {
  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div className="pt-6 text-justify">
          <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Anantaa Intervention
          </h2>
          <p className="pt-4">
            Anantaa provides various intervention services to the couple with the
            primary focus to either prevent or cure parental alienation.
            Anantaa’s approach to the intervention will be to prevent parental
            alienation from happening should a relationship breakdown
            irreparably. Anantaa is not a legal practice and hence does not
            provide legal assistance in any manner. Anantaa’s intervention
            services are done through a team of individuals with good intentions
            to save/restore parent child relationship and has professionals from
            various backgrounds. Anantaa’s team assures full confidentiality and
            secrecy of its services.
            <br /> <br />
            If you feel that either you or someone you know will benefit from
            Anantaa Intervention, please write to us{" "}
            <a
              href="mailto:support@anantaafoundation.org"
              className="cursor-pointer text-blue-500 "
            >
              support@anantaafoundation.org
            </a>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
