export default function CTACommunity() {
  return (
    <div className="bg-white pt-6 ">
      <div className="relative sm:pt-12">
        <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <div className="relative overflow-hidden rounded-lg sm:rounded-2xl bg-[#00CAB9] pattern px-6 py-10 shadow-xl sm:px-12 sm:py-14">
            <div className="relative">
              <div className="text-center">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl max-w-2xl mx-auto">
                  Unite Against Parental Alienation
                </h2>
                <p style={{}} className="mx-auto mt-6 max-w-2xl text-lg text-gray-100 text-justify">
                  Join our supportive community at Anantaa Foundation to heal
                  from parental alienation effects through forgiveness and
                  unity. Send us an mail at community@anantaafoundation.org to
                  join.
                </p>
              </div>
              <div className="mx-auto mt-8 flex  flex-col gap-y-4 sm:flex-row items-center gap-x-6 w-[fit-content] ">
                <a
                  href="mailto:community@anantaafoundation.org"
                  className="min-w-[150px] text-center rounded-md bg-white px-3 py-2.5 text-base font-semibold text-gray-900 shadow-sm  "
                >
                  Join now
                </a>

                <a
                  href="/community"
                  className="min-w-[150px] text-center rounded-md  px-3 py-2.5 text-base font-semibold text-gray-100 border shadow-sm  "
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
