export default function Community() {
  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div className="pt-6 text-justify">
        <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Community
          </h2>

          <p className="pt-4">
            It has been very unusual, especially in India, where the divorced or
            the separated parent tend to be marginalized or shunned from the so
            called main stream society. This is one of the bitter fallouts from
            the legal cases where one party is victimized and most social
            support systems become closed. If you are going through such an
            issue and need support, please join us. You may write to us at{" "}
            <a
              href="mailto:community@anantaafoundation.org"
              className="cursor-pointer text-blue-500 "
            >
              community@anantaafoundation.org
            </a>{" "}
            <br /> <br />
            However, you will be allowed to join the community only after
            evaluation. This community is only for individuals to overcome their
            grief, support each other and heal from the parental alienation
            effects. People considering joining this community should be on the
            mindset to recovery through FORGIVENESS and not carry grudge or
            vengeance against their erring or erstwhile partners.
            <br /> <br />
            If you have free time to spare and would like to volunteer in any of
            the activities of Anantaa, you may write to us at{" "}
            <a
              href="mailto:volunteer@anantaafoundation.org"
              className="cursor-pointer text-blue-500 "
            >
              volunteer@anantaafoundation.org
            </a>{" "}
            <br /> <br />
            Anantaa encourages volunteers who have been victims of parental
            alienation themselves i.e., grown up adults who have suffered
            parental alienation as a child. While you were either SILENCED or
            became VOICELESS as a child and no one to understand your plight
            then, let that not happen to any child now. Please write to us at{" "}
            <a
              href="mailto:volunteer@anantaafoundation.org"
              className="cursor-pointer text-blue-500 "
            >
              volunteer@anantaafoundation.org
            </a>
            <br /> <br />
            <br /> <br />
          </p>
        </div>
      </div>
    </div>
  );
}
