export default function Parental() {
  const signsOfParentalAlienation = [
    "The child constantly and unfairly criticizes the alienated parent (sometimes called a 'campaign of denigration').",
    "The child doesn’t have any strong evidence, specific examples, or justifications for the criticisms — or only has false reasoning.",
    "The child’s feelings about the alienated parent aren’t mixed — they’re all negative, with no redeeming qualities to be found. This is sometimes called 'lack of ambivalence'.",
    "The child claims the criticisms are all their own conclusions and based on their own independent thinking. (In reality, in PA, the alienating parent is said to 'program' the child with these ideas.)",
    "The child has unwavering support for the alienator.",
    "The child doesn’t feel guilty about mistreating or hating the alienated parent.",
    "The child uses terms and phrases that seem borrowed from adult language when referring to situations that never happened or happened before the child’s memory.",
    "The child’s feelings of hatred toward the alienated parent expand to include other family members related to that parent (for example, grandparents or cousins on that side of the family).",
  ];

  const effectsOfParentalAlienation = [
    {
      heading: "Splitting",
      description:
        "This is the psychological phenomenon of seeing people as either 'all bad' or 'all good,' or 'black or white.' Everything is polarized and the person has an inability to see shades of grey. Think of the borderline personality disordered person who has to split in order to cope with relationships and life in general. This is not a disorder you want your child to possess and leads to endless problems.",
    },
    {
      heading: "Difficulties forming and maintaining relationships",
      description:
        "Alienated children struggle with developing healthy relationships because they have been conditioned to 'get rid of people' whenever they experience a perceived threat. Since most people are flawed, the alienated child would need the skill of knowing how to accept flaws in others in order to maintain the relationship. Skills such as flexibility, acceptance, forgiveness, do not exist when you reject people outright for minor infractions, as alienated children have been trained to do. Whenever someone causes a perceived threat to this person, he/she is triggered to remember, 'I know how to handle this,' and they proceed to reject the other person easily. Their mind tells them, 'You just hurt my feelings. I’m going to close you out and now you’re done.'",
    },
    {
      heading:
        "Low Self Image and Lack of impulse control or of ability to tolerate anger or hostility",
      description:
        "Alienated children as adults (and as children) have a very low tolerance for any kind of anger or hostility, which are always interpreted by the person as abuse. They have a difficult time when someone is upset with them. Alienated children as adults have a very difficult time owning their part in a problem, taking responsibility, or making amends to others. They actually get panicked or triggered by any type of perceived disapproval. In order to have healthy relationships, a necessary level of tolerance for others’ negative feelings is essential.",
    },
    {
      heading: "Conflict with authority figures",
      description:
        "Because these individuals have learned how to go around an authority figure with a 'campaign of denigration,' they will carry this habit into their adult lives. You can see this in the workplace if the alienated child has a boss, he/she doesn’t like. He/she will create a crusade against this boss by rallying co-workers against the boss with a smear campaign.",
    },
    {
      heading: "Unhealthy entitlement to a sense of rage",
      description:
        "They have been rewarded for being hostile and angry towards one of their parents, and this rage stays there and can be triggered at any time. Yes, the psychological damage to a child who is trained by one parent to reject another parent has serious and profound negative effects on that child’s ability to form any type of healthy intimate attachments in adulthood.",
    },
  ];

  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div>
          <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            What is Parental Alienation (PA) & Parental Alienation Syndrome
            (PAS)
          </h2>
          <p className="mt-4 text-justify">
            Parental Alienation is a process through which a child becomes
            estranged from a parent as a result of psychological manipulation of
            the other parent. This may manifest itself as fear, hostility or
            disrespect by the child towards the distant parent and also
            relatives/friends of the distant parent. It is widely asserted that
            parental alienation should be recognized as a form of child abuse or
            family violence. It is believed that parental alienation creates
            stress on the alienated parent and the child, and significantly
            increases the risk of the child developing a mental disorder later
            in the life. <br /> <br />
            Richard Gardner, a child psychologist, first coined the term
            <span className="font-semibold text-black"> Parental Alienation Syndrome (PAS) </span>in 1985 to describe the
            behaviours in a child exposed to parental alienation. He identified
            eight signs and symptoms of PAS in children.
          </p>

          <ol className="list-decimal ml-4 mt-6 text-justify">
            {signsOfParentalAlienation.map((item, index) => (
              <li key={index} className="mt-4">
                {item}
              </li>
            ))}
          </ol>
          <p className="mt-6 text-justify">
            While PA refers to the process of alienation of a child by the
            parent, PAS refers to the behaviours of the child towards the
            targeted parent as a result of PA.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Effects of Parental Alienation on child
          </h2>
          <p className="mt-6 text-justify">
            The long-term effects of parental alienation can be devastating on
            the child and can last a life time. Not only does the child miss out
            on a lifetime of having an enjoyable and fulfilling relationship
            with the parent they have been conditioned to reject, they also
            develop some serious pathological behaviours and attitudes that
            carry into their adult lives.
            <br /> <br />
            Following are some of the disturbing effects of parental alienation
          </p>
          <ol className="list-disc ml-4 mt-6 text-justify">
            {effectsOfParentalAlienation.map((item, index) => (
              <li key={index} className="mt-4">
                <p className="font-semibold ">{item?.heading}{" "}: </p>
                {item?.description}
              </li>
            ))}
          </ol>
          <p className="mt-6 text-justify">
            {" "}
            Therefore, it is important to interrupt the alienating process
            during childhood and reward the child for attachment-enhancing
            behaviours toward the rejected parent, before it is too late.
          </p>
        </div>
      </div>
    </div>
  );
}
