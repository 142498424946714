export default function Soon() {
  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div className=" py-6 sm:py-32 font-bold text-center">
          Coming soon...
        </div>
      </div>
    </div>
  );
}
