"use client";
import React, { useEffect } from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import Logo from "./../assets/logo.png";
import { hover } from "@testing-library/user-event/dist/hover";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Parental Alienation", href: "/parental_alienation" },
  { name: "Activities", href: "/activities" },
  { name: "Blogs", href: "/blog" },
  { name: "News/Events", href: "/news-events" },
  { name: "Community", href: "/community" },
  { name: "Contact Us", href: "/contact" },
];

const SubMenu = [
  { name: "Awareness", href: "/activities/awareness" },
  { name: "Prevention", href: "/activities/prevention" },
  { name: "Cure", href: "/activities/cure" },
  { name: "Public Advisory", href: "/activities/public_advisory" },
  { name: "Anantaa Intervention", href: "/activities/anantaa_intervention" },
];

const Header = () => {
  const pathName = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [activeSub, setActiveSub] = useState(false);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    setCurrent(pathName?.pathname);
  }, [pathName]);

  useEffect(() => {
    const activeItem = SubMenu.find((item) => item.href === pathName?.pathname);
    if (activeItem) {
      setActiveSub(true);
    } else {
      setActiveSub(false);
    }
  }, [pathName]);

  const [hovered, setHovered] = useState(false);

  return (
    <header className="inset-x-0 top-0 z-10 w-full fixed bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between px-6 h-[60px] sm:h-[68px] lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/">
            <img
              className="h-10 sm:h-12 w-auto"
              width={1200}
              height={400}
              src={Logo}
              alt="Logo"
            />
          </a>
        </div>

        <button
          type="button"
          className="-m-2.5 lg:hidden inline-flex items-center justify-center rounded-md p-2.5 text-gray-900"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <div key={item.name} className="flex flex-col items-center">
              {item?.name == "Activities" ? (
                <>
                  <div
                    onMouseEnter={() => {
                      setHovered(true);
                    }}
                    onMouseLeave={() => {
                      setHovered(false);
                    }}
                    className="text-sm relative font-medium leading-6 cursor-pointer text-gray-900 "
                  >
                    {item.name}

                    {hovered && (
                      <div className=" absolute top-[20px]  right-[-70px] ">
                        <div className="h-[20px] w-full bg-transaprent"></div>
                        <div className="w-[200px] h-auto bg-white rounded-md flex flex-col items-center py-2 px-2 gap-1 border-2 border-gray-200">
                          {SubMenu.map((item) => (
                            <a
                              className={`${current == item?.href && "bg-gray-100"} hover:bg-gray-100 w-full  px-2 py-1 rounded-md`}
                              key={item.name}
                              href={item?.href}
                            >
                              {item.name}{" "}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {activeSub && (
                    <div className="w-[75%] h-[3px] bg-[#EF7400] rounded-full">
                      {" "}
                    </div>
                  )}
                </>
              ) : (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm relative font-medium leading-6 cursor-pointer text-gray-900 "
                >
                  {item.name}
                </a>
              )}
              {current == item?.href && (
                <div className="w-[75%] h-[3px] bg-[#EF7400] rounded-full">
                  {" "}
                </div>
              )}
            </div>
          ))}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={closeMobileMenu}
      >
        <div className="fixed inset-0 z-50 bg-black/70" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50  w-[80vw] overflow-y-auto bg-white px-6 py-3 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <div className="flex lg:flex-1">
              <a href="/">
                <img
                  className="h-10 w-auto"
                  height={60}
                  width={200}
                  src={Logo}
                  alt="Logo"
                />
              </a>
            </div>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) =>
                  item?.name == "Activities" ? (
                    <>
                      <div
                        onClick={() => {
                          setActiveSub((prev) => !prev);
                        }}
                        className="-mx-3  rounded-lg px-3 py-2 text-lg font-medium leading-7 text-gray-900 flex justify-between items-center"
                      >
                        {item.name}
                        {activeSub ? (
                          <ChevronUpIcon className="w-5 h-5 font-semibold" />
                        ) : (
                          <ChevronDownIcon className="h-5 w-5 font-semibold" />
                        )}
                      </div>
                      {activeSub &&
                        SubMenu.map((item) => (
                          <a
                            className="mx-3 block rounded-lg px-3 py-2 text-lg font-medium leading-7 text-gray-900"
                            key={item.name}
                            href={item?.href}
                          >
                            {item.name}
                          </a>
                        ))}
                    </>
                  ) : (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={() => closeMobileMenu()}
                      className={`-mx-3 block rounded-lg px-3 py-2 text-lg font-medium leading-7 text-gray-900 `}
                    >
                      {item.name}
                    </a>
                  )
                )}
              </div>
              <div className="py-6"></div>
            </div>
          </div>
         
        </Dialog.Panel>
      </Dialog>
      
    </header>
  );
};

export default Header;
