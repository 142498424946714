export default function PublicAdvisory() {
  const relationshipGuidelines = [
    "Avoid talking / gossiping about the issues in their relationship to others even if you know the details of the problems. The information gets twisted and gossips spread fast, hurting the reconciliation efforts.",
    "If you happen to meet the affected parties and they choose to discuss with you, maintain secrecy of the discussion at all times and pacify/comfort them in a neutral way and don’t take sides without listening to the other person's side of the issues.",
    "Never ever advise or take part in any hostile actions like removing the child without knowledge of the other partner, harming the interests of the other partner either in profession, business, or in social environments. These actions can never ever help in finding an amicable lasting solution to resolving the conflict in the relationship.",
    "Remember, there could never be a situation where the fault is only on one party though it may appear so initially, especially if the person manages to gain enough emotional sympathy for her/his cause. Therefore, it would be prudent not to get carried away and stay neutral.",
  ];

  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div className="pt-6 text-justify">
          <h2 className="text-left text-lg font-medium text-[#00CAB9]">
            Public Advisory
          </h2>
          <p className="pt-4">
            It is quite possible that all of us may know/ hear either directly
            or indirectly someone going through relationship problems. Under
            these circumstances, even if we are not able to directly help them
            in reconciliation, we can choose to act responsibly by following
            some of the etiquettes given below.
          </p>

          <ol className="list-disc ml-4 sm:ml-12 mt-6">
            {relationshipGuidelines.map((item, index) => (
              <li key={index} className="mt-4">
                {item}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}
