export default function ContactUs() {
  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div className="pt-6">
        <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Contact Us
          </h2>

          <p className="pt-4 text-justify">
          If you feel that either you or someone you know will benefit from Anantaa Intervention, please write to us {" "}
            <a
              href="mailto:support@anantaafoundation.org"
              className="cursor-pointer text-blue-500 "
            >
              support@anantaafoundation.org
            </a>{" "}
            <br /> <br />
            
          </p>
        </div>
      </div>
    </div>
  );
}
