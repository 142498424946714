export default function Awareness() {
  return (
    <div className="overflow-hidden bg-white pt-36">
      <div className="relative mx-auto  max-w-7xl px-6  lg:px-8 ">
        <div>
          <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Awareness
          </h2>
          <p className="pt-4 text-justify">
            One of the important activities that we propose to do is to raise
            the public consciousness about Parental Alienation. What might you
            do to foster public awareness of parental alienation? For starters,
            just consider educating yourself about Parental Alienation and
            talking about it when you gather with friends and family, especially
            if you have seen signs of alienation in someone they know.
            <br />
            <br />
            Explain what alienation is, and the harm it does to children.
            Explain especially that an alienating parent can very convincingly
            spread false narratives about the targeted parent to turn family
            members and friends against him or her.
            <br />
            <br />
            We at Anantaa indulge in various parental alienation awareness
            campaigns from time to time and if you would like to give your
            suggestions or participate as a volunteer, you may get in touch with
            us at{" "}
            <a
              href="mailto:volunteer@anantaafoundation.org"
              className="cursor-pointer text-blue-500 "
            >
              volunteer@anantaafoundation.org
            </a>{" "}
            <br />
            <br />
          </p>
        </div>

        <div>
          <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Research Articles
          </h2>
          <p className="pt-4 text-justify">
            We believe that education fosters understanding to empower people
            with the will and capacity to stop the emotional and mental abuse of
            innocent children. They can then involve themselves in many useful
            ways within their capacity to help combat parental alienation.
            Therefore, we are giving below certain useful links to scholarly
            articles and research papers on Parental Alienation.
          </p>
        </div>

        <div className="pt-6">
          <h2 className="text-left text-lg font-semibold text-[#00CAB9]">
            Survivors of Parental Alienation
          </h2>
          <p className="pt-4 text-justify">
            There can be no other authentic validation of the ill effects of
            parental alienation than getting the views from the adult survivors
            of parental alienation. While many of them have had their VOICE
            suppressed as a child, their views as a grown up now are a touching
            tale of silent suffering and misery at the hands of alienating
            parent. We encourage the grown-up children who can now voice their
            sufferings to reach out to us with their experiences growing up with
            the alienating parent. This would help us muster support to take up
            the cause for the children who are going through the same now with
            the Government and Policy makers. If you are an adult survivor of
            parental alienation and would like to involve with us, please get in
            touch us at{" "}
            <a
              href="mailto:volunteer@anantaafoundation.org"
              className="cursor-pointer text-blue-500 "
            >
              volunteer@anantaafoundation.org
            </a>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
